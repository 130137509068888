/** use css to override adyen default styling */

.adyen-checkout__loading-input__form {
  display: flex;
  flex-direction: column;
  /* flex-direction: column-reverse; */
  margin-top: -25px;
}

.adyen-checkout__card__form,
.adyen-checkout__card__exp-cvc {
  margin-top: 25px;
}

.adyen-checkout__label__text,
.adyen-checkout__input,
.adyen-checkout__field__cvc {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  color: '#000000';
}

.adyen-checkout__input::placeholder {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
}

.adyen-checkout__label__text.adyen-checkout__label__text--error {
  color: '#000000';
}

.adyen-checkout__field__cvc::placeholder {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  color: #d8d8d8;
}

.adyen-checkout__error-text {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  color: #ff5055;
}

.adyen-checkout__input--error,
.adyen-checkout__input--error:hover,
.adyen-checkout__input--invalid,
.adyen-checkout__input--invalid:hover {
  border-color: #ff5055;
}

.adyen-checkout__button__text {
  content: 'CREATE';
}
